<template>
  <v-col :class="`d-flex flex-wrap justify-center tiles mx-0 ${hasSelectedCity ? 'mb-0' : 'mb-8'}`">
    <div class="align-center select-situation">
      <h2>
        Ville de ma recherche
      </h2>
      <v-row>
        <v-alert v-if="hasAnyError"
                 type="error"
                 class="my-2 pa-2"
                 outlined
                 dense>
          <p class="text-body-2">
            Nous rencontrons un problème technique à cette étape&nbsp;:
            vous pourrez nous préciser cela plus tard.
          </p>
        </v-alert>
      </v-row>

      <vuetify-geo-places
        v-if="userProfile.isInitialized"
        id="searchCity"
        label=""
        :value="userProfile.location"
        :show-radius="true"
        :radius-for-candidates="true"
        @input="updateCity"
        @isOnError="isOnError"
        persistent-hint
        no-data-text="Aucune correspondance"
        dense
        filled
        class="mx-4 combobox-erhgo"
        :rules="[v => !!location || 'Ce champ est obligatoire']"
      />
      <div v-else class="ma-12 d-flex justify-content-center align-center">
        <v-progress-circular
          indeterminate
          class="ma-auto"
          color="primary"/>
      </div>
      <div class="pt-10" v-if="hasSelectedCity">
        <v-sheet
          id="buttonConfirmCity"
          class="button_basic button_primary"
          @click="saveSelectedCity"
        >
          <template v-if="!userProfile.loading">
            Je valide ma zone de recherche
          </template>

          <v-progress-circular
            v-else
            indeterminate
            class="ma-auto"
            color="primary"/>

        </v-sheet>
      </div>
    </div>
  </v-col>
</template>

<script>
import { Situation } from 'erhgo-api-client';
import keycloakService from 'odas-plugins/KeycloakService';
import VuetifyGeoPlaces from 'odas-plugins/VuetifyGeoPlaces';
import userProfile from '@/views/home/UserProfileProvider';

export default {
  name: 'SelectCity.vue',
  components: {VuetifyGeoPlaces},
  data() {
    return {
      btnProps: {
        cols: 6,
        md: 4,
        lg: 3,
        xl: 2,
        class: 'text-center',
      },
      sheetProps: {
        height: '100%',
        class: 'pa-2 d-flex flex-column align-center',
      },
      keycloakService,
      searchCityAPIError: false,
      userProfile,
      location: null,
    };
  },
  async created() {
    await this.userProfile.initialize();
    this.location = this.userProfile.location;
  },
  computed: {
    iconWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '80%';
        case 'sm':
          return '50%';
        case 'md':
          return '50%';
        default:
          return '40%';
      }
    },
    hasSelectedCity() {
      return this.location && this.location.city;
    },
    RESEARCHING() {
      return Situation.RESEARCHING;
    },
    STANDBY() {
      return Situation.STANDBY;
    },
    EMPLOYEE() {
      return Situation.EMPLOYEE;
    },
    hasAnyError() {
      return this.searchCityAPIError || this.userProfile.showAPIError;
    },
  },
  methods: {
    isOnError(isInError) {
      this.searchCityAPIError = isInError;
      this.$emit('searchCityInError', isInError);
    },
    updateCity(place) {
      if (!!place) {
        const {city, citycode, postcode, departmentCode, regionName, latitude, longitude, radiusInKm} = place;
        this.location = {
          city,
          citycode,
          postcode,
          departmentCode,
          regionName,
          latitude,
          longitude,
          radiusInKm,
        };
      } else {
        this.location = null;
      }
    },
    async saveSelectedCity() {
      await this.userProfile.updateUserLocation(this.location);
      if (!this.userProfile.showAPIError) {
        this.$emit('citySaved');
      }
    },
  },
};
</script>

<style>

.combobox-erhgo {
  top: 2em !important;
  position: relative;
}

.combobox-erhgo .v-input__slot > fieldset {
  background-color: white !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 3px #00ad91 solid;
  border-radius: 0.25em 0.25em 0 0;
}

.combobox-erhgo.v-select--is-menu-active .v-input__slot > fieldset {
  border-bottom-color: #00f0c8;
}

.combobox-erhgo .v-label {
  transform: initial;
  bottom: 4em;
  top: initial;
  font-weight: 700 !important;
  font-size: 16px;
  left: -12px !important;
  color: #007a66 !important;
}

.app-ng .v-autocomplete__content {
  border-bottom-left-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
}

.app-ng .v-autocomplete__content .v-select-list {
  padding: 0;
}

.app-ng .v-autocomplete__content .v-select-list .v-list-item {
  border-top: rgba(0, 0, 0, 0.12) solid 1px;
  border-left: rgba(0, 0, 0, 0.12) solid 1px;
  border-right: rgba(0, 0, 0, 0.12) solid 1px;
}

.app-ng .v-autocomplete__content .v-select-list .v-list-item:first-child {
  border-top: none !important;
}

.app-ng .v-autocomplete__content .v-select-list .v-list-item:last-child {
  border-bottom: rgba(0, 0, 0, 0.12) solid 1px;
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}

</style>
