<template>
  <v-card-text class="pt-6 notification-card">
    <p class="text--primary mb-0">
      <strong>{{ notification.entityData.TITLE }} - </strong>&nbsp;{{ notification.entityData.CONTENT }}
    </p>
    <div class="text-right d-flex justify-space-between" v-if="notification.entityData && notification.entityData.URL">
      <em class="my-auto" v-if="timestampText">{{ timestampText }}</em>
      <v-btn
        text
        target="_blank"
        :href="notification.entityData.URL"
        class="primary--text">
        <u>+ d'infos</u>
      </v-btn>
    </div>
  </v-card-text>
</template>
<script>

import NotificationService from '@/components/notifications/NotificationService';

export default {
  name: 'default-notification',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      service: new NotificationService(),
      timestampText: null,
    };
  },
  created() {
    this.timestampText = this.service.notificationTimestampText(this.notification);
  },
};
</script>
<style>

.notification-card {
  margin-top: -0.9rem !important;
  border: solid 1px #d9d9d9;

}
</style>
