<template>
  <v-row v-if="isMobile && isEverywhereButDownloadAppPage"
         no-gutters
         justify="center"
         :style="isInMobileApp ? 'display: none':''"
         style="margin-top: -30px;margin-bottom: 20px;height: 60px ">
    <v-col class="text-center flex-center center-text" cols="12" @click="redirectToAppStore">
      <v-img src="/img/google-fr.png" width="200px" class="ml-auto mr-auto clickable"
             v-if="isAndroid"/>
      <v-img src="/img/apple-fr.png" width="200px" class="ml-auto mr-auto clickable"
             v-if="isIOs"/>
    </v-col>
  </v-row>
</template>
<script>

import { BrowserOrigin, computeBrowserOrigin, redirectToAppStore } from '@/mobile-utils';

export default {
  name: 'application-navbar',
  props: {
    isInMobileApp: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showApplicationNavbar: true,
      browserOrigin: null,
    };
  },
  created() {
    this.browserOrigin = computeBrowserOrigin(this.userAgent);
  },
  computed: {
    userAgent() {
      return navigator.userAgent;
    },
    isAndroid() {
      return this.browserOrigin === BrowserOrigin.ANDROID;
    },
    isIOs() {
      return this.browserOrigin === BrowserOrigin.IOS;
    },
    isMobile() {
      return this.showApplicationNavbar && (this.isAndroid || this.isIOs);
    },
    isEverywhereButDownloadAppPage() {
      return this.$route.name !== 'download_app';
    },
  },
  methods: {
    redirectToAppStore() {
      redirectToAppStore(this.browserOrigin);
    },
  },
};
</script>
<style>

footer {
  z-index: 8 !important;
}

</style>
