<template>
  <v-navigation-drawer
      id="notification-sidebar"
      app
      v-model="drawer"
      stateless
      right
      width="350"
      style="z-index: 20000"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="my-4 text-h4">
          <strong>
            Mes notifications
            <v-icon @click="drawer = false"
                    large>
              close
            </v-icon>
          </strong>
        </v-list-item-title>
        <template v-if="service.hasNotifications">
          <div class="d-flex">
            <v-switch
                id="only-unread-notifications"
                v-model="onlyUnread"
                label="Uniquement les messages non lus"
                class="ml-3"/>
          </div>
          <v-btn
              v-if="notifications.length"
              id="mark-all-as-read"
              class="interaction--text mt-2 mb-6"
              outlined
              @click="service.markAllNotificationsAsRead()">
            Tout marquer comme lu
          </v-btn>
        </template>
        <template v-else>
          Aucune notification pour le moment.
        </template>
        <div
            v-for="(notification, index) in notifications"
            v-bind:key="index"
            class="clickable"
            @click="service.markNotificationAsRead(notification.id)"
            id="notification-list"
        >
          <notification-item class="my-3" :notification="notification" @delete-notification="service.deleteNotification(notification.id)"/>
        </div>
      </v-list-item-content>

    </v-list-item>
  </v-navigation-drawer>

</template>
<script>
import {UserNotificationState} from 'erhgo-api-client';
import keycloakService from 'odas-plugins/KeycloakService';
import Vue from 'vue';
import NotificationItem from '@/components/notifications/NotificationItem';
import NotificationService from '@/components/notifications/NotificationService';


export default {
  name: 'NotificationSidebar',
  components: {NotificationItem},
  data() {
    return {
      UserNotificationState,
      keycloakService,
      drawer: false,
      onlyUnread: false,
      service: new NotificationService(),
    };
  },
  async created() {
    await this.service.initialize();
    if (Vue.$eventBus) {
      Vue.$eventBus.$on('handleNotificationSidebar', () => this.drawer = !this.drawer);
    }
  },
  computed: {
    notifications() {
      return this.service.notifications.filter(n => !this.onlyUnread || n.state === UserNotificationState.NEW);
    },
  },
  methods: {
    async markNotificationAsRead(notificationId) {
      await this.service.markNotificationAsRead(notificationId);
    },
  },
};
</script>

