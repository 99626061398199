import SafeService from 'odas-plugins/SafeService';
import Vue from 'vue';
import keycloakService from 'odas-plugins/KeycloakService';
import { InitializedProfile, UserChannelSource, UserRegistrationStateStep } from 'erhgo-api-client';
import userProfile from '@/views/home/UserProfileProvider';

interface LPMarker {
  key: string;
  timestamp: number;
}

class UserProfileInitializer extends SafeService {

  private static instance: UserProfileInitializer;
  private static KEY = 'LP_MARKER';
  private static CHANNEL_SOURCES = 'channelsources';

  private _initializedProfile: InitializedProfile | null = null;

  public async setMarkerForKey(key: string) {
    const marker = {
      key,
      timestamp: new Date().getTime(),
    };

    if (keycloakService.isAuthenticated) {
      await this.doInitializeProfile(marker);
    } else {
      localStorage[UserProfileInitializer.KEY] = JSON.stringify(marker);
    }
  }

  private async doInitializeProfile(lpMarker: LPMarker | undefined) {
    await this.safeCall(async () => {
      const urlParams = new URLSearchParams(window.location.search);
      let channelSource: UserChannelSource = lpMarker?.key ? UserChannelSource.LANDING_PAGE : UserChannelSource.UNKNOWN;
      let value = lpMarker?.key ? [lpMarker?.key] : undefined;
      if (urlParams.has(UserProfileInitializer.CHANNEL_SOURCES)) {
        channelSource = UserChannelSource.URL;
        value = urlParams.getAll(UserProfileInitializer.CHANNEL_SOURCES) || undefined;
      }
      this._initializedProfile = (await Vue.$api.initializeProfile({
        channelSource,
        value,
      })).data;
      await userProfile.initialize();
    });
  }

  public async initialize() {
    if (keycloakService.isAuthenticated) {
      let lpMarker: LPMarker | undefined;
      const lpMarkerAsString = localStorage[UserProfileInitializer.KEY];
      if (lpMarkerAsString) {
        try {
          lpMarker = JSON.parse(lpMarkerAsString);
          if (this.isObsolete(lpMarker)) {
            lpMarker = undefined;
          }
        } catch (e) {
          // Incorrect lpMarker value - ignore and remove
        } finally {
          localStorage.removeItem(UserProfileInitializer.KEY);
        }
      }
      await this.doInitializeProfile(lpMarker);
    }
  }

  private isObsolete(lpMarker: LPMarker | undefined) {
    return lpMarker && lpMarker.timestamp < new Date().getTime() - 3600 * 1000;
  }

  static getInstance(): UserProfileInitializer {
    if (!UserProfileInitializer.instance) {
      UserProfileInitializer.instance = new UserProfileInitializer();
    }
    return UserProfileInitializer.instance;
  }

  get mandatoryIdentity() {
    return this._initializedProfile?.mandatoryIdentity;
  }

  get step() {
    return this._initializedProfile?.registrationStep;
  }

  get finished() {
    return !this.step || this.step === UserRegistrationStateStep.CONFIRMED_CONTACT_DETAILS
      || (this.step === UserRegistrationStateStep.CONFIRMED_SALARY && (keycloakService.firstName || keycloakService.lastName)) // do not bother old account with 'tell your name' step if it is already known
      || this.step === UserRegistrationStateStep.NOT_AFFECTED
      || this.step === UserRegistrationStateStep.LEGACY_ACCOUNT;
  }

  get requiresEmailConfirmation() {
    return this._initializedProfile?.confirmationRequired ?? false;
  }

  set requiresEmailConfirmation(value) {
    this._initializedProfile!.confirmationRequired = value;
  }

  get correctEmailSuggestion() {
    return this._initializedProfile?.correctEmailSuggestion ?? '';
  }

  markLocallyAsFinished() {
    this._initializedProfile!.registrationStep = UserRegistrationStateStep.CONFIRMED_CONTACT_DETAILS;
  }

  markLocallyAsNotAffected() {
    this._initializedProfile!.registrationStep = UserRegistrationStateStep.NOT_AFFECTED;
  }
}

export default UserProfileInitializer.getInstance();
