<template>
  <v-btn
      class="shiny"
      id="notification-bell"
      icon
      small
      @click="emitHandleNotificationSidebar">
    <v-badge
        v-if="hasUnreadNotifications"
        color="error"
        :content="userProfile.unreadNotificationsCount"
        overlap
    >
      <v-icon>mdi-bell</v-icon>
    </v-badge>
    <v-icon v-else>mdi-bell</v-icon>
  </v-btn>
</template>
<script>
import userProfile from '@/views/home/UserProfileProvider';
import Vue from 'vue';
import userProfileInitializer from '@/domain/user/UserProfileInitializer';

export default {
  name: 'NotificationBell',
  data() {
    return {
      userProfileInitializer,
      userProfile,
    };
  },
  async created() {
    await userProfile.initialize();
  },
  computed: {
    hasUnreadNotifications() {
      return userProfile.unreadNotificationsCount > 0;
    },
  },
  methods: {
    emitHandleNotificationSidebar() {
      Vue.$eventBus.$emit('handleNotificationSidebar');
    },
  },
};
</script>
