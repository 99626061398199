<template>
  <v-snackbar v-if="requiresExperiencesImportDialog" :class="snackbarClasses" v-model="opened" centered multi-line :timeout="-1">
    <div class="mb-1 text-center d-flex">
      <div class="d-flex align-center justify-space-between" style="width: 100%">
        <v-icon class="mb-4" @click="opened = false">fa-times</v-icon>
        <div class="flex-grow-1 text-center">
          <p class="mb-2"><strong class="secondary">Votre profil est incomplet&nbsp;!</strong></p>
          <span>Vous pouvez désormais utiliser notre nouvel outil d'import de CV pour accélérer le processus.</span>
        </div>
      </div>
    </div>
      <div class="d-flex flex-column mt-4">
      <v-btn
        class="interaction clickable my-1"
        @click="goToCorrespondingPage()"
        x-medium
      >
        <v-icon class="white--text" x-small>fal fa-comments-alt
        </v-icon>
        <h5 class="pa-2 white--text text-center">
          <strong>Ajouter des expériences</strong>
        </h5>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import userProfile from '@/views/home/UserProfileProvider';

export default {
  name: 'dialog-import-experiences',
  data() {
    return {
      userProfile,
      opened: false,
    };
  },
  async created() {
    await this.userProfile.initialize();
    this.opened = true;
  },
  computed: {
    requiresExperiencesImportDialog() {
      return userProfile.experiences.length < 2;
    },
    snackbarClasses() {
      return {
        'shake-animation': this.opened && this.requiresExperiencesImportDialog,
      };
    },
  },
  methods: {
    goToCorrespondingPage() {
      this.$router.push({name: 'experiences'});
      this.opened = false;
    },
  },

};
</script>
<style >
.incomplete-list {
  list-style-position: inside !important;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% {
    transform: translateX(-8px);
  }
  50% {
    transform: translateX(8px);
  }
  75% {
    transform: translateX(-8px);
  }
  100% { transform: translateX(0); }
}
.shake-animation {
  animation: shake 0.1s;
  animation-iteration-count: 2;
}

</style>
