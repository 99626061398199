<template>
  <v-dialog
    v-model="isOpen"
    persistent
    width="auto">
    <v-card class="pa-5">
      <select-city
        @searchCityInError="isOnError"
        @citySaved="closePopin"
      />
      <v-sheet @click="closePopin"
               class="button_basic close_button_theme mx-3 my-2">
        Plus tard
      </v-sheet>
    </v-card>
  </v-dialog>
</template>
<script>
import userProfile from '@/views/home/UserProfileProvider';
import SelectCity from '@/components/SelectCity';

export default {
  name: 'dialog-complete-your-location',
  components: {SelectCity},
  data() {
    return {
      userProfile,
      isOpen: true,
      searchCityAPIError: false,
    };
  },
  async created() {
    await this.userProfile.initialize();
  },
  methods: {
    isOnError(isInError) {
      this.searchCityAPIError = isInError;
    },
    async closePopin() {
      this.isOpen = false;
      this.$emit('closeLocationPopin');
    },
  },
};
</script>
<style>
.close_button_theme {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0.75em;
  color: #000000;
  font-weight: 700;
  background-color: lightgrey !important;
}

.close_button_theme:hover,
.close_button_theme:focus {
  background-color: darkgrey !important;
}
</style>
