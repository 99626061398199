<template>
    <router-view/>
</template>

<script>

export default {
  name: 'App',
  methods: {
    addFavicon() {
      const link = document.createElement('link');
      link.rel = 'icon';
      link.type = 'image/png';
      link.href = '/favicons/favicon.ico';
      document.getElementsByTagName('head')[0].appendChild(link);
    },
  },
  created() {
    this.addFavicon();
  },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

@media #{map-get($display-breakpoints, 'sm-and-up')} {

  .shiny-border-sm-and-up {
    border-right-color: var(--v-shiny-base);
    border-right-width: 2px;
    border-right-style: solid;
  }
}

.v-dialog {
  width: auto !important;
}

.v-btn {
  text-transform: none;
}

.no-bullet {
  list-style-type: none;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize-first-letter::first-letter {
  text-transform: uppercase !important;
}

.v-card__text, .v-card__title {
  word-break: normal !important; //see https://github.com/vuetifyjs/vuetify/issues/9130
}

.invisible {
  visibility: hidden;
}

.render-eol {
  white-space: pre-wrap;
}

.v-btn.primary {
  color: black !important;
}

.simple-link {
  cursor: pointer;
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}

.simple-link .v-icon {
  text-decoration: none;
}

</style>
