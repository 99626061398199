export default {
  NAME: {link: 'my_account', description: 'Vos nom & prénom', icon: 'user'},
  PHONE: {link: 'my_account', description: 'Votre téléphone', icon: 'phone'},
  SITUATION: {link: 'edit_classifications', description: 'Votre situation professionnelle', icon: 'briefcase'},
  SALARY: {link: 'edit_classifications', description: 'Votre salaire ciblé', icon: 'euro-sign'},
  LOCATION: {link: 'edit_classifications', description: 'Votre localisation', icon: 'map-marker'},
  CRITERIA: {link: 'criteria_questions', description: 'Vos critères sur votre recherche', icon: 'comment-check'},
  BEHAVIOR: {link: 'edit_behaviors', description: 'Vos souhaits pour votre prochain poste', icon: 'heartbeat'},
  EXPERIENCE: {link: 'experiences', description: 'Vos expériences professionnelles', icon: 'comments-alt'},
  LAST_CONNECTION_DATE: {link: null, description: 'Connectez-vous afin de voir nos différentes offres', icon: 'watch'},
  INACTIVE_CANDIDATURE: {
    link: 'matching_recruitments', description: 'Candidatez à un de nos recrutements', icon: 'pen-fancy',
  },
};
