import { Route } from 'vue-router';

export enum BrowserOrigin {
  ANDROID,
  IOS,
  DESKTOP
}

export function isAndroid(userAgent?: string) {
  return /Android/i.test(userAgent ?? navigator.userAgent);
}

export function isIOs(userAgent?: string) {
  return /iPhone|iPad|iPod/i.test(userAgent ?? navigator.userAgent);
}

export function computeBrowserOrigin(userAgentIn?: string): BrowserOrigin {
  const userAgent = userAgentIn ?? navigator.userAgent;
  if (isAndroid(userAgent)) {
    return BrowserOrigin.ANDROID;
  } else if (isIOs(userAgent)) {
    return BrowserOrigin.IOS;
  } else {
    return BrowserOrigin.DESKTOP;
  }
}

export function redirectToAppStore(browserOriginIn?: BrowserOrigin) {
  const browserOrigin = browserOriginIn ?? computeBrowserOrigin();
  if (browserOrigin === BrowserOrigin.IOS) {
    window.open('https://apps.apple.com/fr/app/jenesuispasuncv/6445897032');
  } else if (browserOrigin === BrowserOrigin.ANDROID) {
    window.open('https://play.google.com/store/apps/details?id=com.erhgo.jenesuispasuncv.mobile&pli=1');
  }
}

export function storeThenGetContextFromMobileApp(route: Route) {
  if (!!(route.query.fromMobile)) {
    localStorage.setItem('isInMobileApp', String(route.query.fromMobile));
  }
  return !!(localStorage.getItem('isInMobileApp'));
}

export function isRedirectingToDownloadAppPageRequired(route: Route) {
  const isInMobileBrowser = computeBrowserOrigin(navigator.userAgent) !== BrowserOrigin.DESKTOP;
  const isNotInMobileApp = !(storeThenGetContextFromMobileApp(route));
  return (
    route.name !== 'spontaneousCandidature' &&
    route.name !== 'download_app' &&
    isInMobileBrowser &&
    isNotInMobileApp &&
    localStorage.getItem('unwantToDownload') !== 'true'
  );
}

export function unwantToDownload() {
  localStorage.setItem('unwantToDownload', 'true');
}
