import Vue from 'vue';
// @ts-ignore
import Vuex from 'vuex';
// @ts-ignore
import user from './domain/user/store';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
  },
  strict: false,
});

export default store;
