<template>
  <v-card-text
    @click="redirectToJobView"
    class="pt-6 notification-card">
    <p class="text--primary mb-0">
      {{ notification.entityData.ORGANIZATION_NAME }} vous sollicite pour un poste de
      {{ notification.entityData.JOB_TITLE }} à {{ notification.entityData.CITY }}
    </p>
    <div class="text-right d-flex justify-space-between">
      <em class="my-auto" v-if="timestampText">{{ timestampText }}</em>
      <v-btn
        text
        class="primary--text">
        <u>Candidater</u>
      </v-btn>
    </div>
  </v-card-text>
</template>
<script>

import NotificationService from '@/components/notifications/NotificationService';

export default {
  name: 'recruitment-notification',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      service: new NotificationService(),
      timestampText: null,
    };
  },
  created() {
    this.timestampText = this.service.notificationTimestampText(this.notification);
  },
  methods: {
    async redirectToJobView() {
      await this.$router.push({
        name: 'job_view',
        params: {code: `R-${this.notification.entityId}`},
      });
    },
  },

};
</script>
<style>

.notification-card {
  margin-top: -0.9rem !important;
  border: solid 1px #d9d9d9;

}
</style>
