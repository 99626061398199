<template>
  <v-footer padless inset class="border-top">
    <v-row no-gutters justify="center" align="center">
      <v-col cols="12" lg="2" class="text-center mx-2">
        <card-btn
            no-border
            href="https://jenesuispasuncv.fr/mentions-legales/"
            target="_blank"
            class="grey lighten-4"
        >
          Mentions légales
        </card-btn>
      </v-col>
      <v-divider vertical/>
      <v-col cols="12" lg="2" class="text-center mx-2">
        <card-btn
          no-border
          href="https://jenesuispasuncv.fr/cgu"
          target="_blank"
          class="grey lighten-4"
        >
          Conditions générales d'utilisation
        </card-btn>
      </v-col>
      <v-divider vertical/>
      <v-col cols="12" lg="2" class="text-center mx-2">
        <card-btn
          no-border
          to=""
          href="https://jenesuispasuncv.fr/politique-de-confidentialite-jenesuispasuncv/"
          target="_blank"
          class="grey lighten-4"
        >
          Politique de confidentialité
        </card-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import CardBtn from 'odas-plugins/CardBtn';

export default {
  name: 'ErhgoFooter',
  components: {CardBtn},
};
</script>

<style>
.border-top {
  border-top: 1px solid var(--v-primary-base) !important;
}
</style>
