<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          dark
          color="primary"
          rounded
          large
          elevation="10"
          :disabled="!userProfile.isExportable"
        >
          <span class="white--text pr-2 d-none d-sm-block">
            Mon profil
           <v-progress-circular
             v-if="loading"
             indeterminate
             color="secondary"
             size="24"
             class="ml-2"
           />
          </span>
          <v-icon color="white">fa-solid fa-file-download</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="downloadProfile(false)" :disabled="loading">
          <v-list-item-icon>
            <v-icon color="secondary">fa-regular fa-file-check</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Profil complet
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="secondary"
              size="24"
              class="ml-2"
            />
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="downloadProfile(true)" :disabled="loading">
          <v-list-item-icon>
            <v-icon color="secondary">fa-solid fa-file-exclamation</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Profil anonyme
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="secondary"
              size="24"
              class="ml-2"
            />
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import keycloakService from 'odas-plugins/KeycloakService';

export default {
  name: 'DownloadProfileButton',
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async downloadProfile(forcedAnonymous) {
      if (this.loading) return;
      this.loading = true;
      try {
        const response = await this.exportPDF(forcedAnonymous);
        const blob = new Blob([response], {type: this.mediaType});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `profile-${forcedAnonymous ? 'anonyme' : this.userProfile.firstName}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } finally {
        this.loading = false;
      }
    },
    async exportPDF(forcedAnonymous) {
      const response = await this.$api.getUserProfileFOPdf(keycloakService.userId, forcedAnonymous, { responseType: 'blob' });
      return response.data;
    },
  },
  computed: {
    mediaType() {
      return this.extension === 'csv' ? 'text/csv;charset=utf-8;' : `application/${this.extension}`;
    },
  },
};
</script>
