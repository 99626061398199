import SafeService from 'odas-plugins/SafeService';
import Vue from 'vue';
import keycloakService from 'odas-plugins/KeycloakService';
import { UserNotification, UserNotificationState } from 'erhgo-api-client';
import moment from 'moment';
import userProfile from '@/views/home/UserProfileProvider';

export default class NotificationService extends SafeService {

  private _notifications: UserNotification[] = [];

  private _notification: UserNotification | null = null;

  private static _comparator(currentNotification: UserNotification, nextNotification: UserNotification) {
    const currentIsFavored = currentNotification.state === UserNotificationState.NEW && nextNotification.state === UserNotificationState.NOT_INTERESTED;
    const nextIsFavored = currentNotification.state === UserNotificationState.NOT_INTERESTED && nextNotification.state === UserNotificationState.NEW;
    if (currentIsFavored) return -1;
    if (nextIsFavored) return 1;
    return moment(currentNotification.createdDate || 0) > (moment(nextNotification.createdDate || 0)) ? -1 : 1;
  }

  constructor() {
    moment.locale('fr');
    super();
  }

  get notifications() {
    return this._notifications;
  }

  get hasNotifications() {
    return !!this._notifications.length;
  }

  async initialize() {
    this._notifications = await this._fetchNotifications();
  }

  async markAllNotificationsAsRead() {
    await this.safeCall(() => Vue.$api.markAllNotificationsAsRead(keycloakService.userId));
    this._notifications = this._notifications.map(n => ({...n, state: UserNotificationState.NOT_INTERESTED}));
    userProfile.resetUnreadNotificationsCount();
  }

  async markNotificationAsRead(notificationId: string) {
    if (!this.loading) {
      await this.safeCall(() => Vue.$api.markNotificationAsRead(notificationId));
      this._notifications = this._notifications.map(n => ({
        ...n,
        state: n.id === notificationId ? UserNotificationState.NOT_INTERESTED : n.state,
      }));
      userProfile.decreaseUnreadNotificationsCount();
    }
  }
  async deleteNotification(notificationId: string) {
    await this.safeCall(() => Vue.$api.deleteUserNotification(notificationId));
    this._notifications = this._notifications.filter(n => n.id !== notificationId);
  }

  async _fetchNotifications() {
    return (await Vue.$api.getUserNotifications(keycloakService.userId))
      .data
      .sort(NotificationService._comparator);
  }

  notificationTimestampText(notification: UserNotification) {
    return moment(notification.createdDate).fromNow();
  }
}
